export default [
	{
		path: '/my-profile',
		component: () => import('../components/MyProfile.vue'),
		name: 'my-profile',
		meta: {	
			guest: false,
			needsAuth: true		
		}
	},

	{
		path: '/edit-profile',
		component: () => import('../components/EditProfile.vue'),
		name: 'edit-profile',
		meta: {		
			guest: false,
			needsAuth: true	
		}
	},
]

export const getMembershipData = (state) => {
	return state.membershipData
}

// export const getMembershipPlanIdEdit = (state) => {
// 	return state.membershipPlanIdEdit
// }


export const getShowInclusionRecruit = (state) => {
	return state.showInclusionRecruit
}
export const hideInclusionRecruit = (state) => {
	return state.hideInclusionRecruit
}

export const getShowInclusionJiipa = (state) => {
	return state.showInclusionJiipa
}
export const hideInclusionJiipa = (state) => {
	return state.hideInclusionJiipa
}

export const getShowSmartPlannerInclusion = (state) => {
	return state.showSmartPlannerInclusion
}
export const hideSmartPlannerInclusion = (state) => {
	return state.hideSmartPlannerInclusion
}

export const getShowPreviewAgreement = (state) => {
	return state.showPreviewAgreement
}
export const hidePreviewAgreement = (state) => {
	return state.hidePreviewAgreement
}

// getting plans without promotions data
export const getMembershipPlan = (state) => {
    return state.membership.membershipPlansData;
}

// getting selected plans without promotions
export const getSelectedPlan = (state) => {
	console.log('dfsd')
	console.log(state)
    return state.membership.selectedPlan;
}

// getting membership plans with promotions
export const getPromotionPlan = (state) => {
  return state.membership.promotionPlans;
}


// getting Discount Promotion list
export const getDiscountPromotions = (state) => {
    return state.discountPromotionsData;
}


// export const getJiipaProductsInclusion = (state) => {
//   return state.membership.jiipaProduct;
// }


export const getOtpEmail = (state) => {
  return state.otpEmail 
};

export const getShowEmailVerifyModel = (state) => {
	return state.showEmailVerifyModel
}

export const hideEmailVerifyModel = (state) => {
	return state.hideEmailVerifyModel
}

export const getContract = (state) => {
	return state.agreement.agreementData;
}
export const getWebLang = (state)=> {
    return state.webLang;
}


export const getShowInclusion = (state)  => {
  return state.inclusions.showInclusion
}


export const getCommissionData = (state) => {
  return state.membership.commissions;
}

export const setListByCategory = (state, data) => {
	return state.listByCategory = data
}

export const setEventListInCrousel = (state, data) => {
	return state.eventListForCarousel = data
}

export const setCategoryList = (state, data) => {
	return state.categoryList = data
}
export default [	
{
	path: '/referrals',
	component: () => import('../components/Referrals.vue'),
	name: 'referrals',
	meta: {	
		guest: true,
		needsAuth: false		
	}
}
]

export const GalleryImageList = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/my-profile-gallery-images').then((response) => {
        commit('setshowGalleryImages', response.data.data);
        return response.data.data
    })
}
 export const GalleryBannerImageList = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/my-profile-gallery-images').then((response) => {
        commit('setshowGalleryBannerImages', response.data.data);
        return response.data.data
        
    })
}

export const fetchEventImages = ({ commit }) => {
	return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/event/list').then((response) => {
		commit('setshowEventsImages' ,response.data.data);
		return response.data.data

	})
}

export const fetchNewsLetterList = ({commit}) => {
	return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/newsletter-list').then((response) => {
		commit('setShowNewsletterImages', response.data.data);
		return response.data.data
	})
}


 export const fetchLanguageList = ({ commit, context }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/get-active-languages').then((response) => {  
        return response.data.data    
    })
}

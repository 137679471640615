export const getUserRolesData = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles').then((response) => {
    commit('SET_ROLE_LIST', response)
    return true
  }).catch((error) => {
    if (error.response.data.message != '') {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
    }
    return false
  })
}

export const fetchUserById = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/user/details').then((response) => {
    commit('SET_EDIT_USER_DATA', response)
    return true
  }).catch((error) => {
    return false
  })
}

export const cityData = ({ commit, context}, query = 'some') => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/get-city/" + query).then((response) => {
    commit("SET_CITY_DATA", response.data);
    return true;
  }).catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
    context.errors = error.response.data.errors;
    return false;
  });   
};

export const stateData = ({ commit, context }, id) => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + "api/states", {
    params: { id },
  }).then((response) => {
      // commit("setAuthenticated", true);
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data.data;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong State", { root: true });
      context.errors = error.response.data.errors;
      return false;
    })  
  };

  export const updateProfile = ({ commit }, { payload, context , id}) => {
    console.log('df')
    console.log(payload);
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/user/update-details', payload,{
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
      }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
      })
    }

    export const profilePic = ({ commit, dispatch }, { payload, context }) => {
      return window.axios.post (process.env.VUE_APP_SSO_API_URL + "api/profile-pic", payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then((response) => {
      // Showing  success message
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
    }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
    })
  }



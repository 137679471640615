const cityData = ({ commit, context}, query = 'some') => {
  
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/get-city/" + query).then((response) => {
      commit("SET_CITY_DATA", response.data);
      return true;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });   
};

const countryData = ({ commit, context}, query = 'some') => {
  
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/countries/" + query).then((response) => {
      commit("SET_COUNTRY_DATA", response.data);
      return true;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });   
};

// get phonecode data api
const phoneData = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/phone-code').then((response) => {
    // commit("setAuthenticated", true);
    commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
    return response.data.data;
  })
    .catch((error) => {
    commit("SET_ALERT", "warning", { root: true });
    commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    // errors = error.response.data.errors;
    return false;
  });
};

// const contractAgreementsData = ({ commit },sign_document) => {
//      // process.env.VUE_APP_SMART_RECRUIT_API_URL + 
//      return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + "api/contract-agreement/"+"&sign_document="+sign_document)
//      .then((response) => {
//         commit("contractAgreements", response.data);
//         commit("HANDLE_MAIN_HEADER_BASED_ON_AUTH", null, { root: true });
//         return response.data;
//     }).catch((error) => {
//         commit('SET_ALERT', 'warning', { root: true })
//         commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
//     });
// };

const verifyEmail = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/verify-email', payload).then((response) => { 
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', "Email Verification Successfully", { root: true })
        return response.data
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response, { root: true })
        context.errors = error.response.data
        return false
    })
}

const verifiedEmail = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/verified-email', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data, { root: true })
        return response.data
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
const languagesList = ({ commit, context}) => {
  
  return window.axios.get(process.env.VUE_APP_JIIPA_API_URL  + "api/languages-list").then((response) => {
      if(response.data.success){
        return response.data.data.data;
      }else{
        return false;
      }
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      return false;
    });   
};

export default {
    verifyEmail,
    verifiedEmail,
    // contractAgreementsData,
	  cityData,
    countryData,
    phoneData,
    languagesList
}
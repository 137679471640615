import { createRouter, createWebHistory } from 'vue-router'
import { routes as routes } from '../app/index'

import PageNotFound from '../views/PageNotFound.vue'
// import beforeEach from './beforeEach'


const otherRoutes = [
/*{
  path: '/',
  name: 'Home',
  component: () => import('../views/Home.vue')

},*/

// {
//   path: '/sign-in',
//   name: 'Sign-In',
//   component: () => import('../views/SignIn.vue')

// },

// {
//   path: '/forgot-password',
//   name: 'Forgot-Password',
//   component: () => import('../views/ForgotPassword.vue')

// },

// {
//   path: '/reset-password',
//   name: 'Reset-Password',
//   component: () => import('../views/ResetPassword.vue')

// },

// {
//   path: '/change-password',
//   name: 'Change-Password',
//   component: () => import('../views/ChangePassword.vue')
// },





// {
//   path: '/user-list',
//   name: 'user-list',
//   component: () => import('../views/UserList.vue')
// },

{
  path: '/agreements',
  name: 'Agreements',
  component: () => import('../views/Agreements.vue')
},

 {
  path: '/view-agreements',
  name: 'View-Agreements',
  component: () => import('../views/ViewAgreements.vue')
},
{
  path: '/agreement-without-promotion',
  name: 'Agreement-Without-Promotion',
  component: () => import('../views/AgreementWithoutPromotion.vue')
},

// {
//   path: '/contact',
//   name: 'Contact',
//   component: () => import('../views/ContactUs.vue')
// },



/*{
  path: '/newsletter',
  name: 'NewsLetter',
  component: () => import('../views/NewsLetter.vue')
},*/

/*{
  path: '/Membership_Form',
  name: 'RequestForMembership',
  component: () => import('../views/RequestForMembership.vue')
},*/

{

  path: '/services',
  name: 'Services',
  component: () => import('../views/Services.vue')
},

// {

//   path: '/about-us',
//   name: 'About-Us',
//   component: () => import('../views/AboutUs.vue')
 
// },


{
  path: '/about',
  name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound
  }
  ]

for (let i = 0; i < otherRoutes.length; i++){
    routes.push(otherRoutes[i]);
  }
  
  const router = createRouter({
    mode: 'hash',
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // scrollBehavior() {
    //     document.getElementById('app').scrollIntoView();
    // }
  })

  export default router





  // const router = createRouter({
  //   mode: 'history',
  //   history: createWebHistory(process.env.BASE_URL),
  //   routes
  // })
  
  // router.beforeEach(beforeEach)
  
  // export default router

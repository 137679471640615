export default [
{
	path: '/newsletter',
	component: () => import('../components/NewsLetter.vue'),
	name: 'newsletter',
	meta: {
		// guest: false,
		// needsAuth: true
	}
},

]

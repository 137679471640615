import { isEmpty } from 'lodash'

export const membershipData = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + "api/membership-plan")
  .then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit('membershipPlan', response.data.data);
      return response.data.data

  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      //  errors = error.response.data.errors;
      return false;
  });
};

// setting selected plan without promotion in order to pass to plan without promotion inlusion modal
export const setPlan = ({ commit }, value) => {
    commit('setSelectedPlan', value);
};

// export const getContractAgreement = ({ commit, payload }) => {  
//     // process.env.VUE_APP_SMART_RECRUIT_API_URL + 
//     return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + "api/contract-agreement/",payload)
//     .then((response) => {
//         commit("contractAgreements", response.data);
//           commit("HANDLE_MAIN_HEADER_BASED_ON_AUTH", null, { root: true });
//           return response.data;
//     }).catch((error) => {
//         commit('SET_ALERT', 'warning', { root: true })
//         commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
//     });
// }


export const promotionPlanData = ({ commit }) => {
  return window.axios
  .get(
        process.env.VUE_APP_JIIPA_API_URL + "api/promotion-plan/list"
      )
  .then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit("setPromotionPlan", response.data);
      return response.data;
  })
  .catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });       
};


export const sendOtp = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/send-otp', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', "Great! Otp Successfully send in your mail", { root: true })
        return response.data
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', "Something went wrong", { root: true })
        context.errors = error.response.data
        return false
    })
}



export const promotionProductsData = ({ commit }, data) => {
  return window.axios
    .get(process.env.VUE_APP_JIIPA_API_URL+ `${data}api/membership-plan`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return false;
    });       
};


// membership data


export const fetchMembershipData = ({commit}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/membership-plan').then((response) => {          
        commit('setMembershipData', response.data.data);    
    })
}


export const fetchScopeList = ({commit}, query = '') => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/membership-scope' + query).then((response) => {          
        commit('setScopeList', response);    
    })
};



export const getContractAgreement = ({ commit },sign_document) => {
    var currentRole = "employer-admin"
    var currentLanguage = localStorage.getItem('localforage/jiipa/locale')
    var currentLanguage = 'EN'
    
    console.log(currentLanguage);
    console.log(currentRole);

    // currentLanguage = currentLanguage.replace(/['"]+/g, '')

    if (isEmpty(currentLanguage)){
        currentLanguage = 'EN'
    }else{
        currentLanguage = currentLanguage.replace(/['"]+/g, '')
    }
        

    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + "api/contract-agreement/get-by-role/"+currentRole+"?language="+currentLanguage+"&sign_document="+sign_document)
    .then((response) => {
        commit("agreementData", response.data);
          commit("HANDLE_MAIN_HEADER_BASED_ON_AUTH", null, { root: true });
          return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response.data, { root: true })
    });
}



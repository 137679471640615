<template>
    <div>
        <!-- Success Alert -->
        <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="alerts.success">
            <div class="text-black px-6 py-4 border-0 rounded mb-4 bg-white shadow-lg inset-y-0 relative z-50 flex items-center gap-4 border border-gray-300">
                 <span>
                    <svg class="w-10 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"/></svg>
                </span>
                <span class="inline-block align-middle mr-8 text-green-500">{{ alerts.message }}</span>
                <button class="absolute bg-transparent text-2xl font-semibold leading-none -right-2 -top-2 outline-none focus:outline-none" v-on:click="$store.commit('UNSET_ALERT', 'success')">
                    <span>
                        <svg class="w-4 p-0.5 rounded-full bg-gray-300 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </span>
                </button>
            </div>
        </div>

        <!-- Warning Alert -->
        <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="alerts.warning">
            <div class="text-black px-6 py-4 border-0 rounded mb-4 bg-white shadow-lg inset-y-0 relative z-50 flex items-center gap-4 border border-gray-300">
                <span>
                    <svg class="w-10 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 14 2-2m0 0 2-2m-2 2-2-2m2 2 2 2m7-2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"/></svg>                
                </span>
                <span class="inline-block align-middle mr-8 text-red-500">{{ alerts.message }}</span>
                <button class="absolute bg-transparent text-2xl font-semibold leading-none -right-2 -top-2 outline-none focus:outline-none" v-on:click="$store.commit('UNSET_ALERT', 'warning')">
                    <span>
                        <svg class="w-4 p-0.5 rounded-full bg-gray-300 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </span>
                </button>
            </div>
        </div>

        <!-- Primary Alert -->
        <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="alerts.primary">
            <div class="text-black px-6 py-4 border-0 rounded mb-4 bg-white shadow-lg inset-y-0 relative z-50 flex items-center gap-4 border border-gray-300">
                <span>
                    <svg class="w-10 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"/></svg>
                </span>
                <span class="inline-block align-middle mr-8 text-blue-500"> alerts.message }}</span>
                  <button class="absolute bg-transparent text-2xl font-semibold leading-none -right-2 -top-2 outline-none focus:outline-none" v-on:click="$store.commit('UNSET_ALERT', 'primary')">
                    <span>
                        <svg class="w-4 p-0.5 rounded-full bg-gray-300 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </span>
                </button>
            </div>
        </div>

        <!-- Default Alert -->
        <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="alerts.default">
            <div class="text-black px-6 py-4 border-0 rounded mb-4 bg-white shadow-lg inset-y-0 relative z-50">
                <span class="inline-block align-middle mr-8">{{ alerts.message }}</span>
                <button class="absolute bg-transparent text-2xl font-semibold leading-none -right-2 -top-2 outline-none focus:outline-none" v-on:click="$store.commit('UNSET_ALERT', 'default')">
                    <span>
                        <svg class="w-4 p-0.5 rounded-full bg-gray-300 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Alert',
        components: {

        },
        computed: {
            ...mapState([
                'alerts'
            ])
        },
        methods: {

        },
        mounted() {
            let vm = this
            let alertList = Object.entries(this.alerts)
            document.body.addEventListener('click', function(){
                alertList.forEach((key, value) => {
                vm.$store.commit('UNSET_ALERT', key[0])
              })
            }, true);

        }
    };
</script>

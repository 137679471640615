export const user = (state) => {
	return state.user
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const loggedInUser = (state) => {
  return state.user.data;
};
<template>
    <div>           
        <!-- <div class="" id="hideBtns">
            <div class="flex space-x-2 xl:space-x-4 mb-3 xl:mt-6 2xl:mt-14 ml-2 w-full" v-if="collapse">
                <div class="flex">
                    <a href="http://dev.sekai-ichi.com/sma/" target="_blank" class="text-red-700 hover:text-white hover:bg-red-700 text-xs font-bold px-2 2xl:px-4 py-1 lg:py-2 2xl:py-2.5 rounded-full border border-gray-300 w-28 text-center my-auto">
                        Smart Recruit
                    </a>
                </div>
                <div class="flex">
                    <a href="http://dev.sekai-ichi.com/jiipa-admin/" target="_blank" class="text-white bg-greenBg text-xs font-bold px-2 2xl:px-4 py-1 lg:py-2 2xl:py-2.5 rounded-full border border-gray-300 w-28 text-center my-auto">
                      JIIPA
                    </a>
                </div>                       
            </div> 
        </div>  -->

        <!-- <div class="mt-14" v-if="!collapse"></div> -->

        <div class="sidebar" :class="[!collapse ? collapseClass : normalClass]">
            <transition name="slide">
                <section>
                        <div class="h-sidebarHeight 2xl:h-sidebarHeight2XL overflow-y-scroll" id="scrollbar">
                            <div class="flex px-4" v-if="collapse">
                                <div class="w-10/12 font-semibold flex justify-end mr-10 xl:mr-10">
                                    <router-link to="my-profile" class="my-auto text-sm xl:text-base hover:underline hover:text-activeBG "><!-- My Profile -->{{$t('CADL-h3')}}</router-link>
                                </div>
                                <div class="collapse-icon" :class="{ 'mb-4 pl-2': !collapse, 'w-2/12 flex justify-end': collapse }">
                                    <!-- <label v-on:click="collapse = !collapse" class="cursor-pointer"> -->
                                    <label v-on:click="collapseSidebar" class="cursor-pointer">
                                       <svg class="fill-current w-4 text-white" viewBox="0 0 26 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.25 0L25.25 37.5H21.0833L21.0833 0H25.25ZM14.8333 0L14.8333 37.5H10.6667L10.6667 0H14.8333ZM4.41667 0L4.41667 37.5H0.25L0.25 0H4.41667Z"/></svg>
                                    </label>         
                                </div>      
                            </div>
                            <!-- <label v-if="!collapse" v-on:click="collapse = !collapse" class="cursor-pointer"> -->
                            <label v-if="!collapse" v-on:click="collapseSidebar" class="cursor-pointer">
                                <svg class="fill-current w-8 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
                            </label>

                            <label class="mt-3 mb-5 space-y-3">
                                <div class="flex justify-center mt-2 xl:mt-4 max-h-28" v-if="collapse">
                                    <img :src="myProfile.profile_pic" class="profile object-cover rounded-full w-28 h-sidebarProfile">
                                </div>

                                <router-link to="my-profile" class="menu_active space-y-0.5 flex flex-col text-center hover:bg-red-700 mx-10 py-1" v-if="collapse">
                                    <label class="text-sm font-semibold">{{myProfile.name  + "  " + myProfile.last_name}}</label>
                                    <label class="text-small font-semibold">{{myProfile.designation}}</label>
                                    <label class="text-small font-semibold">{{myProfile.user_role}}</label>
                                </router-link>
                            </label>

                            <div v-if="collapse" class="border-b-2 mx-6 my-2"></div>                   

                            <div v-if="!collapse" class="mx-2 border-b-2 py-4 relative cursor-pointer group inline-block ">
                                <img :src="myProfile.profile_pic" class="profile rounded-full" style="width:2.4rem; height:2.4rem;">
                                <!-- <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-24 -left-1/2 ml-14 px-3 pointer-events-none">
                                    {{$t('CADL-h3')}}
                                    <svg class="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div> -->
                            </div>

                            <div class="space-y-2">
                                <div>
                                    <router-link to="dashboard" class="menu_active font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>
                                            <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 22.5h20" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.75 4.5v18h4.5v-18c0-1.1-.45-2-1.8-2h-.9c-1.35 0-1.8.9-1.8 2ZM3 10.5v12h4v-12c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2ZM17 15.5v7h4v-7c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Dashboard -->{{$t('CADL-h7')}}</label>
                                    </router-link>
                                </div>

                                <div>
                                    <router-link :to="{name:'role-and-permission'}" class="menu_active font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>
                                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.15C2 2.72 2.72 2 4.15 2h4.3c1.43 0 2.15.72 2.15 2.15V6M6.7 18H4.15C2.72 18 2 17.28 2 15.85V8.04" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.37 8.42v11.16c0 1.61-.8 2.42-2.41 2.42H9.12c-1.61 0-2.42-.81-2.42-2.42V8.42C6.7 6.81 7.51 6 9.12 6h5.84c1.61 0 2.41.81 2.41 2.42Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.4 6V4.15c0-1.43.72-2.15 2.15-2.15h4.3C21.28 2 22 2.72 22 4.15v11.7c0 1.43-.72 2.15-2.15 2.15h-2.48M10 11h4M10 14h4M12 22v-3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Roles & permission -->{{$t('CADL-h8')}}</label>
                                    </router-link>
                                </div>

                                <div>
                                    <router-link :to="{name:'user-list'}" class="menu_active font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>   
                                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 8c0-4-2-6-6-6H8C4 2 2 4 2 8v13c0 .55.45 1 1 1h13c4 0 6-2 6-6v-4" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 9.5h2M7 9.5h5M7 14.5h7" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>         
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- User List -->{{$t('CADL-h10')}}</label>
                                    </router-link>
                                </div>

                                <div>
                                    <router-link to="agreements" class="menu_active font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>   
                                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11.75H3c-.96 0-1.75-.79-1.75-1.75V6.68c0-.68.47-1.37 1.1-1.62l9-3.6c.38-.15.92-.15 1.3 0l9 3.6c.63.25 1.1.95 1.1 1.62V10c0 .96-.79 1.75-1.75 1.75Zm-9-8.91c-.04 0-.08 0-.1.01l-8.99 3.6c-.06.03-.16.16-.16.23V10c0 .14.11.25.25.25h18c.14 0 .25-.11.25-.25V6.68c0-.07-.09-.2-.16-.23l-9-3.6c-.02-.01-.05-.01-.09-.01Zm10 19.91H2c-.41 0-.75-.34-.75-.75v-3c0-.96.79-1.75 1.75-1.75h18c.96 0 1.75.79 1.75 1.75v3c0 .41-.34.75-.75.75Zm-19.25-1.5h18.5V19c0-.14-.11-.25-.25-.25H3c-.14 0-.25.11-.25.25v2.25Z" fill="#fff"/><path d="M4 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm3 4H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75ZM12 9.25c-1.24 0-2.25-1.01-2.25-2.25S10.76 4.75 12 4.75 14.25 5.76 14.25 7 13.24 9.25 12 9.25Zm0-3c-.41 0-.75.34-.75.75s.34.75.75.75.75-.34.75-.75-.34-.75-.75-.75Z" fill="#fff"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Agreements -->{{$t('CADL-h9')}}</label>
                                    </router-link>
                                </div> 

                                <div>
                                    <router-link to="referrals" class="menu_active font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>   
                                            <svg width="24" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.467 7.371c2.79-2.28 1.17-6.809-2.437-6.809-3.608 0-5.227 4.53-2.437 6.81a3.828 3.828 0 0 0-1.414 2.97v2.943c0 .494.401.895.896.895h5.91a.896.896 0 0 0 .896-.895V10.34a3.828 3.828 0 0 0-1.414-2.97ZM12 2.36a2.05 2.05 0 0 1 2.048 2.048A2.05 2.05 0 0 1 12 6.455a2.05 2.05 0 0 1-2.048-2.048A2.05 2.05 0 0 1 12 2.36Zm2.09 10.03H9.97v-2.012c0-.976.786-1.92 1.751-2.061a2.058 2.058 0 0 1 2.369 2.025v2.048ZM6.288 20.625c2.79-2.28 1.17-6.809-2.437-6.809-3.608 0-5.227 4.53-2.437 6.81A3.828 3.828 0 0 0 0 23.595v2.943c0 .494.401.895.896.895h5.91a.896.896 0 0 0 .896-.895v-2.944a3.828 3.828 0 0 0-1.414-2.97Zm-2.426-5.018a2.05 2.05 0 0 1 2.048 2.048 2.05 2.05 0 0 1-2.048 2.048 2.05 2.05 0 0 1-2.047-2.048 2.05 2.05 0 0 1 2.047-2.048ZM5.91 25.643H1.791v-2.012c0-.976.785-1.921 1.751-2.061a2.059 2.059 0 0 1 2.368 2.025v2.048ZM22.586 20.625c2.79-2.28 1.17-6.809-2.437-6.809-3.608 0-5.227 4.53-2.437 6.81a3.828 3.828 0 0 0-1.413 2.97v2.943c0 .494.4.895.895.895h5.91A.896.896 0 0 0 24 26.54v-2.944a3.828 3.828 0 0 0-1.414-2.97Zm-2.448-5.018a2.05 2.05 0 0 1 2.047 2.048 2.05 2.05 0 0 1-2.047 2.048 2.05 2.05 0 0 1-2.048-2.048 2.05 2.05 0 0 1 2.048-2.048Zm2.071 10.036h-4.12v-2.012c0-.976.786-1.921 1.752-2.061a2.059 2.059 0 0 1 2.368 2.025v2.048ZM15.286 21.346l-2.39-2.39v-3.38a.896.896 0 0 0-1.791 0v3.38l-2.39 2.39a.896.896 0 1 0 1.266 1.266l2.02-2.02 2.018 2.02a.895.895 0 1 0 1.267-1.266Z" fill="#fff"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Referrals -->{{$t('CADL-h11')}}</label>
                                    </router-link>
                                </div>

                                <div>
                                    <a href="services" class="menu_active font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>   
                                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 22h8c4.02 0 4.74-1.61 4.95-3.57l.75-8C21.97 7.99 21.27 6 17 6H7c-4.27 0-4.97 1.99-4.7 4.43l.75 8C3.26 20.39 3.98 22 8 22ZM8 6v-.8C8 3.43 8 2 11.2 2h1.6C16 2 16 3.43 16 5.2V6" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 13v1.02c0 1.09-.01 1.98-2 1.98-1.98 0-2-.88-2-1.97V13c0-1 0-1 1-1h2c1 0 1 0 1 1Zm7.65-2A16.484 16.484 0 0 1 14 14.02M2.62 11.27c2.25 1.54 4.79 2.47 7.38 2.76" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Services -->{{$t('CADL-h12')}}</label>
                                    </a>
                                </div> 

                                <div>
                                    <a href="events" class="font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label>   
                                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2v3M16 2v3M3.5 9.09h17M3 13.01V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.995 13.7h.01M8.294 13.7h.01M8.294 16.7h.01" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Events & Exhibitions -->{{$t('CADL-h13')}}</label>
                                    </a>
                                </div> 

                                <div>
                                    <a href="newsletter" class="font-semibold tracking-wider text-xs flex md:space-x-4 xl:space-x-6 py-2 px-5 hover:bg-red-700 cursor-pointer">
                                        <label> 
                                            <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/><path d="M14 17H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/></svg>
                                        </label>
                                        <label class="my-auto" v-if="collapse"><!-- Newsletter -->{{$t('CADL-h14')}}</label>
                                    </a>
                                </div>

                            </div>
                        </div>

                        <div class="flex justify-center my-2 border-t border-white">
                            <router-link to="#" class="font-semibold tracking-wider flex space-x-5 py-2 pl-10 pr-12 hover:bg-red-700 cursor-pointer mt-2">
                                <label>
                                    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.74 22.27h-.13c-4.44 0-6.58-1.75-6.95-5.67-.04-.41.26-.78.68-.82.4-.04.78.27.82.68.29 3.14 1.77 4.31 5.46 4.31h.13c4.07 0 5.51-1.44 5.51-5.51V8.74c0-4.07-1.44-5.51-5.51-5.51h-.13c-3.71 0-5.19 1.19-5.46 4.39-.05.41-.4.72-.82.68a.751.751 0 0 1-.69-.81c.34-3.98 2.49-5.76 6.96-5.76h.13c4.91 0 7.01 2.1 7.01 7.01v6.52c0 4.91-2.1 7.01-7.01 7.01Z" fill="#fff"/><path d="M15.5 12.75H4.12c-.41 0-.75-.34-.75-.75s.34-.75.75-.75H15.5c.41 0 .75.34.75.75s-.34.75-.75.75Z" fill="#fff"/><path d="M6.35 16.1c-.19 0-.38-.07-.53-.22l-3.35-3.35a.754.754 0 0 1 0-1.06l3.35-3.35c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L4.06 12l2.82 2.82c.29.29.29.77 0 1.06-.14.15-.34.22-.53.22Z" fill="#fff"/></svg>
                                </label>
                                <label v-if="collapse"><!-- Sign Out -->{{$t('CADL-h18')}}</label>
                            </router-link>
                        </div>
                </section>
            </transition>
        </div>    
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default  ({

    data: function() {
        return {
            collapse: true,
            normalClass: 'w-56 xl:w-sidebarWidth bg-blueBg h-sidebarHeight2XL rounded-r-xl py-4 text-white',
            collapseClass: 'w-16 bg-blueBg h-sidebarHeight2XL rounded-r-xl py-4 text-white',

            myProfile: {
                name:"",
                user_role: "",
                last_name: "",
                designation:"",        
                profile_pic:null,
            },
        }
    },
components: {
},

methods: {
    ...mapActions({
        getUserRolesData: 'profile/getUserRolesData',
        fetchUserById: 'profile/fetchUserById',
    }),
    ...mapGetters({
        user: 'auth/user',
        getMyRoleList: 'profile/getMyRoleList',
        getEditUserData: 'profile/getEditUserData'
    }),

    collapseSidebar: function() {
        this.collapse = !this.collapse;
        this.$root.collapseSidebar();  
    },
},

beforeMount() {
    this.$store.commit('setShowLandingHeader', false)
  },
     created() {
        this.fetchUserById()
    },
mounted() {
    this.$store.commit('isAuthenticated', false)
    this.myProfile = this.getEditUserData()
  },
});
</script>

<style scoped>
    .collapse-icon {
      transition: 0.1s linear;
      }
      .sidebar{
        transition: 0.1s ease;      
    }
 /*img.profile:hover {
  border:4px solid #CCAFAF;
}*/
.slide-enter-active {
  transition: 0.6s;
}
.slide-leave-active{
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}


/*~~~~~~~~~~~~~~scrollbar classes*/
#scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#scrollbar::-webkit-scrollbar-track {
  background: #0068a3; 
}
 
/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
    /*background: #888; */
    border-radius: 10px;
}

/* Handle on hover */
#scrollbar:hover::-webkit-scrollbar-thumb {
  background: #8dc6e7; 
}
/*~~~~~~~~~~~~~~~~~~~~*/

.menu_active.router-link-exact-active{
  background-color: #B91C1C;
  color: #ffffff;
  text-decoration: none;
  
}
</style>
<template>
    <div>  
        <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
        <div>   
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
                    <div class="border-2 rounded-2xl shadow-lg rounded-2xl relative flex  bg-white outline-none focus:outline-none max-w-lg mx-auto">        
                        <div class="p-5 w-full">
                            <div class="flex justify-between">
                                <div class="px-0 md:px-6">
                                    <label class="text-xl md:text-2xl font-bold border-l-4 uppercase border-red-600 px-2 tracking-wide"><!-- Feedback -->{{$t('EUMP1-foot-mod-head-4-con3')}}
                                    </label>
                                    <div class="text-sm mt-2">
                                        <!-- Please provide your feedback regarding our JIIPA -->{{$t("provide-feedback-regarding-jiipa")}}
                                    </div>
                                </div>
                                <div class="relative">
                                    <button class="absolute inset-x-2 -top-7" type="button" @click="hideAddFeedback('false')">
                                        <svg class=" bg-white rounded-full" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z" fill="#DA5352"/></svg>
                                    </button>
                                </div>
                            </div>

                            <form @submit.prevent="submitFeddback">
                                <div class=" px-0 md:px-6 py-6 space-x-2 md:space-x-0 space-y-4">
                                 <div class="flex">
                                    <div class="w-5/12">
                                        <div>
                                            <label>
                                                <img v-if="authenticated == true" :src="$store.getters['auth/user']['data'].profileImageUrl" class="w-28 h-28 border-2 rounded-md object-cover">
                                                <img v-else src="/images/profile02.jpg" class="w-28 h-28 border-2 rounded-md object-cover" >
                                            </label>
                                        </div>                                        
                                    </div>
                                    <div class="w-7/12 space-y-3">
                                        <div class="w-full">

                                            <input v-model="email" @blur="clearValidity('email')"  :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': !feedbackvalid.email  }"  type="text" name="email" class="px-4 py-1 placeholder-gray-600 text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('CALMP1-h10')">
                                            <span v-if="!feedbackvalid.email" class="pt-2 text-xs text-red-400">{{$t('email-field-is-required')}}</span>
                                            <span v-if="!feedbackvalid.invalidEmail" class="pt-2 pl-16 text-xs text-red-400">Invalid Email</span>
                                        </div>
                                        <div class="w-full flex-col rounded-md space-y-3">
                                            <input v-model="name" @blur="clearValidity('name')" :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': !feedbackvalid.name  }" type="text" name="name" class="px-4 py-1 placeholder-blue-600 text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('first-name')"> 
                                            <span v-if="!feedbackvalid.name" class="pt-2 text-xs text-red-400">{{ $t('name-field-required') }}</span>                                           
                                        </div>
                                        <div class="w-full flex-col rounded-md space-y-3">
                                            <input v-model="last_name" @blur="clearValidity('last_name')" :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': !feedbackvalid.last_name  }" type="text" name="last name" class="px-4 py-1 placeholder-blue-600 border border-gray-300 text-sm text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('last-name')">        
                                            <span v-if="!feedbackvalid.last_name" class="pt-2 text-xs text-red-400">{{ $t('last-name-field-required') }}</span>                                           
                                        </div>                                        
                                    </div>
                                </div>

                                <div class="w-full">
                                    <textarea v-model="message" @blur="clearValidity('message')" v-bind:class="{ 'border border-2 border-red-300': !feedbackvalid.message  }" rows="3" type="text" name="text" class="px-4 py-1 placeholder-blue-600 text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" placeholder="">
                                    </textarea>
                                    <span v-if="!feedbackvalid.message" class="pt-2 text-xs text-red-400">{{$t('message-field-required')}}</span> 
                                </div>

                                <div>
                                    <div class="md:flex space-x-4">
                                        <div><span class="text-blue-600 text-sm">
                                            <!-- Rate Us -->{{$t('rate-us')}}</span></div>
                                            <div class="flex space-x-1">
                                                <svg class="cursor-pointer fill-current" v-on:change="star" v-for="(i,key) in stars" :value="key ++" :class="i.class" @click="handleStars(i.number); clearValidity('star')" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><rect fill="none" height="24" width="24"/><path d="M14.43,10l-1.47-4.84c-0.29-0.95-1.63-0.95-1.91,0L9.57,10H5.12c-0.97,0-1.37,1.25-0.58,1.81l3.64,2.6l-1.43,4.61 c-0.29,0.93,0.79,1.68,1.56,1.09L12,17.31l3.69,2.81c0.77,0.59,1.85-0.16,1.56-1.09l-1.43-4.61l3.64-2.6 c0.79-0.57,0.39-1.81-0.58-1.81H14.43z"/></g></svg>
                                            </div>
                                        </div>
                                        <span v-if="!feedbackvalid.star" class="pt-2 text-xs text-red-400">{{$t('please-click-on-stars')}}</span>
                                </div>


                                <div class="flex justify-end">
                                    <button type="submit" class="text-sm border bg-greenBg hover:bg-green-700 text-white font-semibold justify-center block flex md:w-28 w-20 md:py-2 py-1 rounded-3xl" :disabled="isbeingSubmit">
                                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="isbeingSubmit"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                                        <span v-show="!isbeingSubmit"><!-- Submit -->{{$t('CALMP1-h35')}}</span>
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>                                 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default  ({
  data: function() {
    return {
        isbeingSubmit: false,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,  
            

        email: '',
        name: '',
        message: '',
        last_name: '',
        star: '',
        status: '',
        // profilePreview: "images/profile02.jpg",
        stars: [
            {number: '1', class:'text-gray-400'},
            {number: '2', class:'text-gray-400'},
            {number: '3', class:'text-gray-400'},
            {number: '4', class:'text-gray-400'},
            {number: '5', class:'text-gray-400'},
        ],
        // errors:[],
        submitted: false,
        feedbackvalid:{
            email : true,
            invalidEmail : true,
            name : true,
            last_name : true,
            message : true,
            star : true,
        },
      
    }
},
components: {

},
computed: {
  ...mapGetters({
         authenticated: 'auth/authenticated',
         loggedInUser: 'auth/loggedInUser',
     }),
},
methods: {     
    ...mapMutations({
        'hideAddFeedback':'feedbackReviews/hideAddFeedback',
    }),

    ...mapActions({
        // fetchUser: 'auth/fetchUser',
        addFeedback: 'feedbackReviews/addFeedback',
    }),

    getUser() {
        this.name = this.loggedInUser.details.name;
        this.last_name = this.loggedInUser.details.last_name;
        this.email = this.loggedInUser.details.email;
    },

    clearValidity(field) {
        this.feedbackvalid[field] = true;
    },

validateForm() {
  let formIsValid = true;

  if (!this.email) {
    this.feedbackvalid.email = false;
    formIsValid = false;
    }else if (!this.reg.test(this.email)) {
        this.feedbackvalid.invalidEmail = false;
        formIsValid = false;
    }
    if (!this.name) {
        this.feedbackvalid.name = false;
        formIsValid = false;
    }
    if (!this.last_name) {
        this.feedbackvalid.last_name = false;
        formIsValid = false;
    }
    if (!this.message) {
        this.feedbackvalid.message = false;
        formIsValid = false;
    }
    if (!this.star) {
        this.feedbackvalid.star = false;
        formIsValid = false;
    }
    
  return formIsValid
},

    submitFeddback(){
        if(this.validateForm()){
            this.isbeingSubmit = true; 
            let formData = new FormData();
            formData.append('email',this.email); 
            formData.append('name',this.name); 
            formData.append('last_name',this.last_name); 
            formData.append('message',this.message); 
            formData.append('photo',this.photo); 
            formData.append('star',this.star); 
            formData.append('status',this.status); 
            this.addFeedback({
                payload: formData,
                context: this,
            }).then((success)=> {
                if(success){
                    this.hideAddFeedback();
                    
                }else{
                    this.isbeingSubmit = false
                }
            })
        }   
       
    },  

    handleStars: function(starNo){
        this.star = starNo
        for (var i = 0; i <= this.stars.length-1; i++) {
            if (i < starNo) {
                this.stars[i].class = 'text-yellow-500'
            } else {
                this.stars[i].class = 'text-gray-400'                    
            }
        }
    },
 
},
mounted() {
    if(this.authenticated == true){
        this.getUser();
    }
},



});
</script>

<style>
 
</style>
// Put your main states here
export default {
    isLoading: false,
    fullPage: true,
    color: 'red',
    loader: 'dots',
    opacity: 0.8,
    isAuth: false,
    locale: 'en',
    showLandingHeader: true,
    countries: [],
    alerts: {
        default: false,
        success: false,
        warning: false,
        info: false,
        message: null
    },
    headers: {
        mainHeaderLinks: [
            {name: 'Dashboard', link: 'dashboard', class: 'text-gray-700 hover:text-blue-500', show: false},
            {name: 'Sign In', link: 'sign-in', class: 'text-sm space-x-1 flex text-white font-bold hover:text-gray-500', show: true},     
        ]
    }
}

export default [

{
	path: '/events',
	component: () => import('../components/EventParent.vue'),
	children:[
		{
			path: '/events',
			component: () => import('../components/Events.vue'),
			name: 'events',
			meta: {	
				guest: true,
				needsAuth: false		
			}
		},

		{
			path: '/category',
			component: () => import('../components/ShowEventDetails.vue'),
			name: 'view-events',
			meta: {	
				guest: true,
				needsAuth: false		
			}
		},

		{
			path: '/old-events',
			component: () => import('../components/EventOld.vue'),
			name: 'old-events',
			meta: {	
				guest: true,
				needsAuth: false		
			}
		},
	]
},



]
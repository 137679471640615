export default [

{
	path: '/dashboard',
	component: () => import('../components/Dashboard.vue'),
	name: 'dashboard',
	meta: {	
		guest: false,
		needsAuth: true		
	}
},



]
import { isEmpty } from 'lodash'

export const fetchReferralList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/list-referral' + query).then((response) => {
        commit('SET_REFERRAL_LIST', response.data.data.data)        
    })
}

export const deleteReferralPlan = ({ commit, state, dispatch }, id) => {
     id = state.idDeleteReferralPlan
    console.log(id);
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + 'api/delete-referral/'+id+'').then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // window.location = "referrals"
        dispatch("fetchReferralList")        
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    });   
};

export const inviteReferral = ({ commit }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_JIIPA_API_URL + 'api/invite-referral', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // window.location = "referrals"
        return true
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}

// Add feedback
export const addFeedback = ({ commit }, { payload, context }) => { 
    return window.axios.post (process.env.VUE_APP_JIIPA_API_URL + 'api/feedback-review', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
        // window.location = "referrals"
        // return true
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// 
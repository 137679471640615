import { createApp } from 'vue'
import axios from 'axios'
import localforage from 'localforage'
import gliderHelper from './plugins/js/glider-helper.js'
window.glider = gliderHelper

import App from './App.vue'
import router from './router'
import store from './store/store.js'
import './index.css'
import './plugins/js/glider.min.js'
import './plugins/css/glider.min.css'
import i18n from './i18n'


localforage.config({
    driver: localforage.LOCALSTORAGE,
    storeName: 'jiipa'
})

window.axios = axios.create({
    baseURL: process.env.VUE_APP_SSO_API_URL || '',
    headers: {
        Accept: 'application/json'
    }
})

window.alertDuration = process.env.VUE_APP_ALERT_DURATION || 2000

store.dispatch('auth/setToken').then(() => {
    store.dispatch('auth/fetchUser').then(() => {
       
    }).catch(() => {
        store.dispatch('auth/clearAuth')
        router.replace({ name: 'sign-in'})
    })
}).catch(() => {
    store.dispatch('auth/clearAuth')
    
})

createApp(App).use(i18n).use(store).use(router).mount('#app')


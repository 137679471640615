export const setshowGalleryImages = (state, data) => {
	return state.showGalleryImages = data
}

export const setshowGalleryBannerImages = (state, data) => {
	return state.showGalleryBannerImages = data
}

export const setshowEventsImages = (state , data) => {
	return state.showEventsImages = data
}

export const setShowNewsletterImages = (state , data) => {
	return state.showNewsletterImages = data
}

 export const setLanguageList = (state, data) => {
	state.languageList = data
}


// list Events Categories 



export const fetchListByCategory = ({ commit }, payload , query ) => {
   	return window.axios.get( process.env.VUE_APP_JIIPA_API_URL + 'api/event-list-by-date?categoryName=' + payload.categoryName+'&occurrence=' + payload.occurrence).then((response) => {
        commit('setListByCategory', response.data.data);
        return response.data.data

     })
}



// List events in carousel
export const fetchEventListinCarousel = ({ commit }) => {
   	return window.axios.get( process.env.VUE_APP_JIIPA_API_URL + 'api/latest-event-list').then((response) => {
        commit('setEventListInCrousel', response.data.data);
          return response.data.data

     })
}

export const fetchCategoryList = ({ commit }, query = '') => {
    var  url ='';
    if(query && query.searchQuery && (query.searchQuery != 'undefined')){
        url = process.env.VUE_APP_JIIPA_API_URL + 'api/event-category-list?name='+ query.searchQuery;
    }else{
        url = process.env.VUE_APP_JIIPA_API_URL + 'api/event-category-list';
    }
    return window.axios.get(url).then((response) => {
        commit('setCategoryList', response.data.data);
        return response.data;
        
    })
}
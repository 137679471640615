export default { 
    user: {
        token: null,
        authenticated: false,
        data: null,
        roleName: null,
        roleSlug: null,
    }, 
    showUserDeleteModel: false,
    hideUserDeleteModel: false,
    phoneCode: [],
    cities: [],
    userListForCurrentUser: [],
    userBeingDeleted: null,
    listUsers: {
        data: [],
        links: [],
        userId:[]
    },
    userEdit:[],
    myRoles: [],
}

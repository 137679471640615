export default [
	{
		path: '/all-feedback',
		component: () => import('../components/partials/AllFeedbacks.vue'),
		name: 'all-feedback',
		meta: {
			
		}
	},

]

export const getListByCategory = (state) => {
	return state.listByCategory
}

export const getEventListInCrousel = (state) => {
	return state.eventListForCarousel
}

export const getCategoryList = (state) => {
	return state.categoryList
}
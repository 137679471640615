export const SET_ROLE_LIST = (state, data) => {
    state.myRoles = data.data
}

export const SET_EDIT_USER_DATA = (state, data) => {
	state.myProfile.name = data.data.data.details.name
	state.myProfile.email = data.data.data.details.email
	state.myProfile.user_role = data.data.data.details.roles[0].show_name
	state.myProfile.last_name = data.data.data.details.last_name 
	state.myProfile.company_logo = data.data.data.details.company_logo 
	state.myProfile.designation = data.data.data.designation
	state.myProfile.department = data.data.data.department
	state.myProfile.pin_code = data.data.data.details.pin_code 
	state.myProfile.company_name = data.data.data.company
	state.myProfile.city = data.data.data.details.city 
	state.myProfile.state = data.data.data.details.state 
	state.myProfile.country = data.data.data.details.country 
	state.myProfile.address_line_1 = data.data.data.details.address_line_1 
	state.myProfile.company_tel = data.data.data.details.company_tel 
	state.myProfile.company_fax = data.data.data.details.company_fax 
	state.myProfile.mobile_number = data.data.data.details.mobile_number 
	state.myProfile.website = data.data.data.details.website 
	state.myProfile.profile_pic = data.data.data.profileImageUrl 
}


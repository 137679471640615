// export const showUserDeleteModel = (state) => {
// 	return state.showUserDeleteModel
// }
export const user = (state) => {
	return state.user
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const getPhoneCode = (state) => {
	return state.phoneCode
}

export const getCityData = (state) => {
	return state.cities
}

export const getUserList = (state) => {
	return state.userListForCurrentUser
}

export const showUserDeleteModel = (state) => {
	return state.showUserDeleteModel
}

export const hideUserDeleteModel = (state) => {
	return state.hideUserDeleteModel
}

// Integration with pagination, seraching and sorting
export const getUserListData = (state) => {
	return state.listUsers.data
}
export const getUserListLinks = (state) => {
	return state.listUsers.links
}
export const getUserId = (state) => {
	return  state.listUsers.userId;
}

export const getUserListMeta = (state) => {
	return state.listUsers.meta
}

export const getUserEditDetails = (state) => {
    return state.userEdit
}

export const getMyRoleList = (state) => {
	return state.myRoles
}
export const getshowGalleryImages = (state) => {
	return state.showGalleryImages 
}

export const getshowGalleryBannerImages = (state) => {
	return state.showGalleryBannerImages 
}
 export const getshowEventsImages = (state) => {
 	return state.showEventsImages
 }

 export const getShowNewsletterImages = (state) => {
    return state.showNewsletterImages
 }


 export const getLanguageList = (state) => {
  return state.languageList.data
}
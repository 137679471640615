import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const forgotpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/forgot-password', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}


export const resetpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/reset', payload).then((response) => {
        // Showing success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}


export const changepassword = ({ commit, dispatch }, { payload, context }) => {
     return window.axios.post(process.env.VUE_APP_SSO_API_URL + "api/change-password" , payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}


export const login = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/signin', payload).then((response) => {
        // Showing success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // Setting up auth token ad fetching user data
        dispatch('setToken', response.data.token).then(() => {
            dispatch('fetchUser')
        })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.isbeingLoggedIn = false
        if (!isEmpty(error.response.data.errors)) {
            context.errors = error.response.data.errors
        }
        return false
    })
}
export const logout = ({ dispatch }) => {
    setHttpBaseUrl(null)
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/logout').then((response) => {
        dispatch('clearAuth')
    })
}

export const setToken = ({ commit, dispatch }, token) => {
    if (isEmpty(token)) {
        return dispatch('checkTokenExists').then((token) => {
            setHttpToken(token)
            setHttpBaseUrlAfterLogin(token)
        })
    }
    commit('setToken', token)
    setHttpToken(token)
}

export const fetchUser = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/user/details').then((response) => {
        commit('setAuthenticated', true)
        commit('setUserData', response.data.data)
        commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
    })
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN')
        }
        return Promise.resolve(token)
    })
}


export const checkIfRoleSelected = ({commit}, context) => {
    commit('CHECK_ROLE_SELECTION', context)
}


export const clearAuth = ({commit}) => {
    commit('setAuthenticated', false)
    commit('setUserData', null)
    commit('setToken', null)
    setHttpToken(null)
    setHttpBaseUrl(null)
    commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
}


export const setHttpBaseUrlAfterLogin = (token) => {
    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN')
        }

        setHttpBaseUrl(token)
        return Promise.resolve(token)
    })
}
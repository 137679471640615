export const fetchPhoneCode = ({ commit }) => {
  return window.axios.get (process.env.VUE_APP_SSO_API_URL + 'api/phone-code').then((response) => {
    commit('setAuthenticated', true);
    commit('setPhoneCode', response.data.data);
  })
}

// contact us
export const sendContactUs = ({ commit }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/contact-us', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// 
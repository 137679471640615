<template>
    <div>  
        <div id="feedback" class="container mx-auto max-w-6xl lg:mt-0  mt-5 pt-16 pb-6 space-y-8 px-2 md:px-4 xl:px-0">
            <div class="flex justify-between">
                <div class="px-3 border-l-8 border-red-600 my-auto py-1">
                    <label class="md:text-2xl text-gray-700 font-bold uppercase"><!-- Review / Feedback -->{{$t('SASG-h8')}}
                    </label>
                </div>
                <div class="my-auto">
                    <router-link :to="{name:'all-feedback'}" class="flex justify-center text-center px-2 md:px-7 py-2 md:py-3 w-28 md:w-full bg-red-600 uppercase text-xs md:text-md border border-white text-white rounded-xl"><!-- View All -->{{$t('view-all')}}
                    </router-link>
                </div>
            </div>

            <div class="container mx-auto mt-4 px-6 md:px-0">
                <div class=" glider-contain">
                    <div class="review-slider" v-on:mouseover="pauseReviewSlide" v-on:mouseleave="nextSlideFeedback">
                        <div class="flex" v-for="feedbackPublishList in getFeedbackPublishList" :key="feedbackPublishList.id">
                            <div class="p-4 w-full">
                                <div class="flex justify-center -mb-10 h-profileHeight">
                                    <img src="/images/profile02.jpg" class=" bg-white h-40 w-36 border border-gray-300 rounded-xl object-contain">
                                </div>
                                <div class="border border-b-2 shadow rounded-md">
                                    <p class="px-4 pt-14 pb-4 h-feedbackHeight overflow-hidden text-sm">{{ feedbackPublishList.message }}
                                    </p>
                                    <div class="border-t flex px-3 py-3">
                                        <div class="w-8/12 flex flex-col text-left">
                                            <label class="text-cyan font-semibold">
                                            {{ feedbackPublishList.first_name }}
                                            {{ feedbackPublishList.last_name }}
                                        </label>
                                        </div>
                                        <div class="w-4/12 flex justify-end my-auto">
                                            <div class="flex space-x-1" v-for="n in parseInt(feedbackPublishList.star)">
                                                <svg class="fill-current text-yellow-400" xmlns="http://www.w3.org/2000/
                                                svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path 
                                                d="M0 0h24v24H0V0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 
                                                9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button aria-label="Previous" class="review-prev">
                        <svg class="w-10 text-gray-400 hover:text-gray-600 border absolute -bottom-6 right-16 bg-gray-100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                    </button>
                    <button aria-label="Next" class="review-next" id="nextNextSlide">
                        <svg class="w-10 text-gray-400 hover:text-gray-600 border absolute -bottom-6 right-0 bg-gray-100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </button>
                    <div role="tablist" class="dots"></div> 
                </div>    
                <div class="my-auto mt-14 md:mt-0">
                    <button @click="showAddFeedback(true)" class="text-center px-4 md:px-7 py-2 md:py-3 bg-red-600 uppercase text-md font-semibold border border-white text-white rounded-xl shadow-md"><!-- Add your feedback -->{{$t('EUG-h5')}}
                    </button> 
                </div>
            </div>           
        </div>

        <!-- ~~~~~~~~model box add your feedback -->
        <div v-if="getShowAddFeedback">
            <AddFeedback/>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import AddFeedback from './partials/AddFeedback.vue'

export default  ({
  data: function() {
    return {                
      autoSlide:null,
      first_name:'',
      last_name:'',
      // email:'',     
      star:'',
      
  }
},
components: {
    AddFeedback
},
computed: {
    ...mapGetters({
        getShowAddFeedback: 'feedbackReviews/getShowAddFeedback', 
        getFeedbackPublishList: 'feedbackReviews/getFeedbackPublishList',

    }),
    ...mapState({
      auth: 'auth'
     })
},
methods: {    
    ...mapMutations({
        'showAddFeedback':'feedbackReviews/showAddFeedback',
    }),
    ...mapActions({
            fetchFeedbackPublishList: 'feedbackReviews/fetchFeedbackPublishList',
        }),
    getFeedback() {
            this.fetchFeedbackPublishList().then((response)=>{
                if(response){
                    this.nextSlideFeedback()
                }
            })
        },
    nextSlideFeedback() {
        window.glider('review-slider', 'review-prev', 'review-next', 1, 2, 3);
        const feedNextButton = document.getElementById('nextNextSlide');
        if (feedNextButton) {
          feedNextButton.addEventListener('click', () => {
          });
          this.autoSlide = setInterval(() => {
            feedNextButton.click();
        }, 4000);
      }

    },
    pauseReviewSlide(event) {  
        clearInterval(this.autoSlide);
    },    

    },
    mounted() {
        // setTimeout(function(){
        //     this.nextNextSlide;  
        // }, 2000);
        this.getFeedback();   
       
    },



});
</script>

<style>

</style>
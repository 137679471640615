import auth from './auth/routes'
import home from './home/routes'
import userList from './userList/routes'
import profile from './profile/routes' 
import roleAndPermission from "./roleAndPermission/routes";
import Events from "./Events/routes";
import referrals from "./referrals/routes";
import gallery from "./gallery/routes";
import membership from "./membership/routes";
import aboutUs from "./aboutUs/routes";
import newsLetter from "./newsLetter/routes";
import dashboard from "./dashboard/routes";
import contactUs from "./contactUs/routes";
import feedbackReviews from "./feedbackReviews/routes";


export default [ ...auth, ...home, ...userList, ...profile, ...roleAndPermission , ...Events, ...referrals, ...gallery, 
				...membership, ...aboutUs, ...newsLetter, ...dashboard, ...contactUs, ...feedbackReviews]


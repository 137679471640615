<template>
    <div>
        <!-- <CommonHeader  v-show="this.$store.state.showLandingHeader"/> -->
        <div v-show="this.$store.state.showLandingHeader" class="">
            <LandingPageHeader />
        </div>
        <!-- <CommonHeader v-show="this.$store.state.showLandingHeader"/> -->

        <div class="flex" v-show="this.$store.state.showLandingHeader" v-if="isAuth">
            <!-- tab and large screen -->
            <div :class="[sidebarMenuClass]">
                <div class="block 2xl:hidden">
                    <Sidebar class="fixed h-full" id="sidebar" />
                </div>
                <div class="hidden 2xl:block">
                    <Sidebar class="fixed h-full" id="sidebarLg" />
                </div>
            </div>
            <!-- ~~~~ -->
            <!-- mobile screen -->
            <div class="w-full block md:hidden">   
                <MobileSidebar/>
            </div>
            <!-- ~~~~~~ -->
            <div class="w-full router-container" :class="[containerClass]" id="container-box">
                <router-view/>
                <div :class="[reviewClass]">
                    <FeedbackReviews />                
                </div>
                <div class="" :class="[footerClass]">     
                    <CustomerFooter/> 
                </div>
            </div>
        </div>

        <div v-if="!isAuth">
            <router-view  />
        </div>
        <FeedbackReviews v-if="!isAuth && !isActive('all-feedback')" />    
        <Footer v-if="!isAuth" />  
        <Alert/>  
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

import LandingPageHeader from './app/home/components/partials/LandingPageHeader.vue'
// import CommonHeader from './components/partial/CommonHeader.vue'
import Sidebar from './components/partial/Sidebar.vue'
import MobileSidebar from './components/partial/MobileSidebar.vue'
import FeedbackReviews from './app/feedbackReviews/components/FeedbackReviews.vue'
import Footer from './components/partial/Footer.vue'
import Alert from './components/partial/Alert.vue'
import CustomerFooter from './components/partial/CustomerFooter.vue'

export default {
  data: function() {
    return {
        sidebarMenu: true,
        sidebarMenuClass: 'w-48 lg:w-64 lg:block hidden',
        containerClass: 'w-full lg:w-10/12 2xl:w-full',
        footerClass: 'w-full md:pl-1 xl:pl-footerPadding 2xl:ml-0',
        reviewClass: 'max-w-6xl 2xl:max-w-full pl-customPadding',
    }
  },
  components: {
    LandingPageHeader,
    // CommonHeader,
    Sidebar,
    MobileSidebar,
    FeedbackReviews,
    Footer,
    CustomerFooter,
    Alert,
  },
   computed: mapState([
    'isLoading',
    'color',
    'loader',
    'opacity',
    'fullPage',
    'isAuth',
    'isCollapse',
  ]),

beforeMount() {
    let vm = this
     window.onpopstate = function(event) {
      vm.$store.commit('setShowLandingHeader', false)
   };
  },
  
  methods: {
     collapseSidebar() {
             this.sidebarMenu = !this.sidebarMenu
             if (this.sidebarMenu){
                this.sidebarMenuClass = 'w-48 lg:w-64 md:block hidden';
                this.containerClass = 'w-full md:w-10/12 2xl:w-full';
                this.footerClass = 'w-full md:pl-1 xl:pl-footerPadding 2xl:ml-0';
                this.reviewClass = 'max-w-6xl 2xl:max-w-full xl:pl-customPadding';
             }
             else {
                this.sidebarMenuClass = 'w-16 md:block hidden';
                this.containerClass = 'w-full md:w-12/12 xl:pl-2 md:px-0';                
                this.footerClass = 'w-full ';                
                this.reviewClass = 'max-w-6xl 2xl:max-w-full xl:pl-customCollapsePadding';                
             }
        },

        isActive(routeName) {
          return this.$route.name === routeName;
        },     
  },

  mounted() {
    this.$store.commit('IS_LOADING', true)
    // simulate AJAX
    setTimeout(() => {
      this.$store.commit('IS_LOADING', false)
    }, 1000)


    let hideScroll = window.pageYOffset;
    window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        if (window.pageYOffset < 3 ) {

            if(document.getElementById("navbar")){
                document.getElementById("navbar").style.top = "0";
            }
            if(document.getElementById("sidebar")){
                document.getElementById("sidebar").style.top = "8rem";
            }
            if(document.getElementById("sidebarLg")){
                document.getElementById("sidebarLg").style.top = "10rem";
            }
            // if(document.getElementById("hideBtns")){
            //     document.getElementById("hideBtns").style.display = "block";
            // }
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.top = "2.8rem";
            }
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.position = "static";
            }

        } else {

            if(document.getElementById("sidebar")){
                document.getElementById("sidebar").style.top = "0";
            }

            if(document.getElementById("sidebarLg")){
                document.getElementById("sidebarLg").style.top = "0";
            }
            if(document.getElementById("navbar")){
                document.getElementById("navbar").style.top = "-165px";
            }
            // if(document.getElementById("hideBtns")){
            //     document.getElementById("hideBtns").style.display = "none";
            // }
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.top = "0";
            }
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.position = "sticky";
            }
        }
        hideScroll = currentScrollPos;
    }
},

};
</script>

<style>
    #navbar {
      transition: top 0.3s;
  }

  #sidebar {
      transition: top 0.1s;
  } 
  #sidebarLg {
      transition: top 0.1s;
  }
  .fade-enter-active, .fade-leave-active {
      transition: 0.1s all ease-out;
  }

  .fade-enter, .fade-leave-to {
      opacity: 0;
  }
.router-container{    
    transition: 0.2s ease;      
  }

</style>



import { isEmpty } from 'lodash';

// list of feedback
export const fetchFeedbackPublishList = ({commit}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/feedback-publish-list').then((response) => {          
        commit('setFeedbackPublishList', response.data.data);   
        return response.data.data 
    })
}
// ~~~~~~~~~

// Add feedback
export const addFeedback = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/feedback-review', payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      // location.reload();      
      return response.data;
    })
    .catch((error) => {
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        // return false
    })
}
// 
export const getShowAddFeedback = (state) => {
	return state.showAddFeedback
}
export const hideAddFeedback = (state) => {
	return state.hideAddFeedback
}

export const getFeedbackPublishList = (state) => {
	return state.feedbackPublishList
}

export default {
  state: {
    showAddPreviewAgreement: false,
    hideAddPreviewAgreement: false,
    showAddSmartRecruit: false,
    hideAddSmartRecruit: false,
    
},
   
  getters: {
    showAddPreviewAgreement(state) {
      return state.showAddPreviewAgreement
    },
    hideAddPreviewAgreement(state) {
      return state.hideAddPreviewAgreement
    },
     showAddSmartRecruit(state) {
      return state.showAddSmartRecruit
    },
    hideAddSmartRecruit(state) {
      return state.hideAddSmartRecruit
    }
   
  },
  mutations: {
    showAddPreviewAgreement(state, flag) {
      state.showAddPreviewAgreement = flag
    },
    hideAddPreviewAgreement(state) {
      state.showAddPreviewAgreement = false
    },
     showAddSmartRecruit(state, flag) {
      state.showAddSmartRecruit = flag
    },
    hideAddSmartRecruit(state) {
      state.showAddSmartRecruit = false
    }
    
  },
}
export default [
	{
		path: '/gallery',
		component: () => import('../components/ParentGallery.vue'),
		meta: {	
			guest: true,
			needsAuth: false		
		},
		children:[
			{
				path: '/gallery',
				component: () => import('../components/Gallery.vue'),
				name: 'gallery',
			},

			{
				path: '/view-gallery',
				component: () => import('../components/ViewGallery.vue'),
				name: 'view-gallery',
			}
		]
	},



]
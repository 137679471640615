export const showAddFeedback = (state, flag) => {
	state.showAddFeedback = flag
}
export const hideAddFeedback = (state) => {
	state.showAddFeedback = false
}

export const setFeedbackPublishList = (state, data) => {
	return state.feedbackPublishList = data
}

export default {
  showEmailVerifyModel:false,
  hideEmailVerifyModel:false,
  
  showInclusionRecruit: false,
  hideInclusionRecruit: false,

  showInclusionJiipa: false,
  hideInclusionJiipa: false,

  showSmartPlannerInclusion: false,
  hideSmartPlannerInclusion: false,	

  showPreviewAgreement: false,
  hidePreviewAgreement: false,
  membership: {
   membershipPlansData: [],
   selectedPlan: [],
   promotionPlans:[],
   recruitProduct:[],
   smartPlannerProduct:[],
   commissions:{},
      },
      agreement: {
        agreementData:[],
      },
      otpEmail: null,
      inclusions : {
        showWithPlanInclusion:false,
        showInclusion: false,
        showSmartPlannerInclusion:false,
      }
    }

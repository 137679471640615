import { isEmpty } from 'lodash'

export const setHttpToken = (token) => {
    if (isEmpty(token)) {
        window.axios.defaults.headers.common['ApiToken'] = null
        window.axios.defaults.headers.common['Authorization'] = null
        window.axios.defaults.headers.common['ProductCode'] = null
        window.axios.defaults.headers.common['ClientType'] = null
    }
    window.axios.defaults.headers.common['ApiToken'] = 'Bearer ' + token
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    window.axios.defaults.headers.common['ProductCode'] = 'JIIPA'
    window.axios.defaults.headers.common['ClientType'] = 'jiipa-client'
}

export const setHttpBaseUrl = (token) => {
    console.log('setHttpBaseUrl');
    if (!isEmpty(token)) {
        window.axios.defaults.baseURL = process.env.VUE_APP_JIIPA_API_URL
        console.log(process.env.VUE_APP_JIIPA_API_URL);
        return
    }
    window.axios.defaults.baseURL = process.env.VUE_APP_SSO_API_URL
}

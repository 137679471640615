
// ~~~~~~~~~List Gallery~~~~~~~~~
export const fetchGalleryList = ({ commit }) => {
    return window.axios.get (process.env.VUE_APP_JIIPA_API_URL + 'api/my-profile-gallery').then((response) => {
        commit('setGalleryList', response.data.data);
    })
}


// ~~~~~~~~~List Gallery images~~~~~~~~~
export const carryGalleryImageList = ({ commit }) => {
    return window.axios.get (process.env.VUE_APP_JIIPA_API_URL + 'api/my-profile-gallery-images').then((response) => {
        commit('setGalleryImageList', response.data.data);
        return response.data.data 
    })
}
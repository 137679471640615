export const setMembershipData = (state, data) => {
	return state.membershipData = data
}


// export const showEditMembershipPlan = (state,value) => {
// 	state.membershipPlanIdEdit = value
// }


export const showInclusionRecruit = (state, flag) => {
	state.showInclusionRecruit = flag
}
export const hideInclusionRecruit = (state) => {
	state.showInclusionRecruit = false
}

export const showInclusionJiipa = (state, flag) => {
	state.showInclusionJiipa = flag
}
export const hideInclusionJiipa = (state) => {
	state.showInclusionJiipa = false
}

export const showSmartPlannerInclusion = (state, flag) => {
	state.showSmartPlannerInclusion = flag
}
export const hideSmartPlannerInclusion = (state) => {
	state.showSmartPlannerInclusion = false
}

export const showPreviewAgreement = (state, flag) => {
	state.showPreviewAgreement = flag
}
export const hidePreviewAgreement = (state) => {
	state.showPreviewAgreement = false
}

// setting membership plan without promotions
export const membershipPlan = (state, data) => {
  state.membership.membershipPlansData = data;
};

// setting selected membership plans without promotions(IMP)
export const setSelectedPlan = (state, data) => {
	// console.log('dfdsfdsfdsfds')
	// console.log(state,data)

  state.membership.selectedPlan = data;
};

// setting membership plans with promotions
export const setPromotionPlan = (state, data) => {
  state.membership.promotionPlans = data;
};



// promotion List
export const discountPromotions = (state, data) => {
  state.discountPromotionsData = data;
};

// email verify
export const setOtpEmail = (state, email) => {
  state.otpEmail = email;
};

export const showEmailVerifyModel = (state, flag) => {
	state.showEmailVerifyModel = flag
}

export const hideEmailVerifyModel = (state) => {
	state.showEmailVerifyModel = false
}

// contract agreemnet data
export const agreementData = (state, data) => {
	console.log("data")
	console.log(state, data)
	
  state.agreement.agreementData = data
};

export const showInclusion = (state, flag) =>{
  state.inclusions.showInclusion = flag;
}
export const hideInclusion = (state) =>{
  state.inclusions.showInclusion = false;
}

export const setCommissionData = (state, data) => {
  console.log(data)
  state.membership.commissions = data;
};



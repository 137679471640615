export default [
{
	path: '/role-and-permission',
	component: () => import('../components/RoleAndPermission.vue'),
	name: 'role-and-permission',
	meta: {	
		guest: false,
		needsAuth: true,		
	}
},
{
	path: '/role-add',
	component: () => import('../components/RoleAdd.vue'),
	name: 'role-add',
	meta: {	
		guest: false,
		needsAuth: true,		
	}
},

{
	path: '/role-edit/:id',
	component: () => import('../components/RoleEdit.vue'),
	name: 'role-edit',
	props: true,
	meta: {	
		guest: false,
		needsAuth: true,		
	}
},

{
	path: '/role-view/:id',
	component: () => import('../components/RoleView.vue'),
	name: 'role-view',
	meta: {	
		guest: false,
		needsAuth: true,		
	}
},
]

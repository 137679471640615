import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const createUser = ({ commit, dispatch }, { payload, context }) => {
  return window.axios.post (process.env.VUE_APP_SSO_API_URL + 'api/users/create',payload).then((response) => {
    commit('SET_ALERT', 'success', { root: true })
    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
    return response.data.success
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    context.errors = error.response.data.errors;
    return false
  })
}

export const fetchUserList = ({ commit, dispatch  }, query = '') => {
  return window.axios.get (process.env.VUE_APP_JIIPA_API_URL + 'api/list-user'+ query).then((response) => {
    commit('setAuthenticated', true);
    commit('setUserList', response);
  })
}

export const getUserRolesData = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles').then((response) => {
    commit('SET_ROLE_LIST', response)
    return true
  }).catch((error) => {
    if (error.response.data.message != '') {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
    }
      return false
  })
}

export const deleteUser = ({ commit, state, dispatch },{payload, email}) => {
  email = state.userBeingDeleted
  return window.axios.post (process.env.VUE_APP_SSO_API_URL + `api/users/sso-delete/${email}`,payload).then((response) => {
    commit('setAuthenticated', true);
    commit('SET_ALERT', 'success', { root: true })
    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
    dispatch("fetchUserList")
    return response.data.success
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    context.errors = error.response.data
    return false
  })
}

export const editUserData = ({ commit, state }, email) => {
  return window.axios.get (process.env.VUE_APP_SSO_API_URL + `api/user/details/${email}`).then((response) => {
    console.log(response.data,response.data.data)
    commit('setUserDetails', response.data);
    return response;
  })
}

export const updateUserData = ({commit}, { payload, context, id }) => {
  console.log(payload.id);
  return window.axios({
        method: 'POST',
        url: process.env.VUE_APP_SSO_API_URL + `api/user/update-details-by-id/${payload.id}`,
        data: payload,
        }).then((response) => {
    commit('setAuthenticated', true);
    commit('SET_ALERT', 'success', { root: true })
    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
    return response.data.success
  }).catch((error) => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    context.errors = error.response.data
    return false
  })
}
export default {
  state: {
    showUserDeleteModel: false,
    hideUserDeleteModel: false,
    
},
   
  getters: {
    showUserDeleteModel(state) {
      return state.showUserDeleteModel
    },
    hideUserDeleteModel(state) {
      return state.hideUserDeleteModel
    }
    
  },
  mutations: {
    showUserDeleteModel(state, flag) {
      state.showUserDeleteModel = flag
    },
    hideUserDeleteModel(state) {
      state.showUserDeleteModel = false
    }
    
  },
}
import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import AddPreviewAgreement from "./modules/AddPreviewAgreement.js";
import RoleAndPermission from "./modules/RoleAndPermission.js";
import UserList from "./modules/UserList.js";
import auth from '../app/auth/vuex/index.js';
import roleAndPermission from '../app/roleAndPermission/vuex/index.js';
import Events from '../app/Events/vuex/index.js';
import feedbackReviews from "../app/feedbackReviews/vuex/index.js";
import referrals from "../app/referrals/vuex/index.js";
import profile from "../app/profile/vuex";
import gallery from "../app/gallery/vuex/index.js";
import home from "../app/home/vuex/index.js";
import membership from "../app/membership/vuex/index.js";
import userList from "../app/userList/vuex/index.js";
import aboutUs from "../app/aboutUs/vuex/index.js";
import newsLetter from "../app/newsLetter/vuex/index.js";
import dashboard from "../app/dashboard/vuex/index.js";
import contactUs from "../app/contactUs/vuex/index.js";


export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    AddPreviewAgreement: AddPreviewAgreement,
    RoleAndPermission: RoleAndPermission,
    UserList: UserList,
    auth: auth,
    roleAndPermission: roleAndPermission,
    Events: Events,
    feedbackReviews:feedbackReviews,
    referrals:referrals,
    profile:profile,
    gallery:gallery,
    membership:membership,
    userList:userList,
    aboutUs:aboutUs,
    newsLetter:newsLetter,
    home:home,
    dashboard:dashboard,
    contactUs:contactUs,
  }
});

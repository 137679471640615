export default [
	{
		path: '/',
		component: () => import('../components/Home.vue'),
		name: 'home',
		meta: {
			
		}
	}
]

export default {
	myRoles: [],
	cities: [],
	myProfile: {
		name:"",
		email:"",
		password:"",
		confirm_password:"",
		user_role: "",
		last_name: "",
		company_logo: "",
		designation:"",
		department:"",
		pin_code: "",
		company: "",
		city: "",
		state: "",
		country: "",
		address_line_1: "",
		company_tel: "",
		company_fax: "",
		mobile_number: "",
		website: "",
		guard_name:process.env.VUE_APP_GUARD_NAME_ROLE,
		s_token: process.env.VUE_APP_SSO_PUBLIC_KEY,
		c_token: process.env.VUE_APP_JIPPA_PUBLIC_KEY
	}
}
export const getShowDeleteModel = (state) => {
	return state.showDeleteModel
}
export const hideDeleteModel = (state) => {
	return state.hideDeleteModel
}

export const getShowInviteReferrals = (state) => {
	return state.showInviteReferrals
}
export const hideInviteReferrals = (state) => {
	return state.hideInviteReferrals
}

///integration
export const getReferralListData = (state) => {
	return state.referralList.data
}
export const getReferralListLinks = (state) => {
	return state.referralList.links
}
export const getPlanId = (state) => {
	return  state.referralList.referralId;
}

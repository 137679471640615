import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const SET_ROLE_LIST = (state, data) => {
    state.myRoles = data.data
}

export const showDeleteModel = (state, flag) => {
    state.showUserDeleteModel = flag
}

export const setPhoneCode = (state, data) => {
    state.phoneCode = data
}

export const SET_CITY_DATA = (state, data) => {
    state.cities = data
}

export const setUserList = (state, data) => {
    state.listUsers.data = data.data.data
    state.listUsers.links = data.data.links
    state.listUsers.meta = data.data.meta
}

export const showDeleteUser = (state, {flag, value}) => {
    state.showUserDeleteModel = flag
    state.userBeingDeleted = value
}

export const hideDeleteUser = (state) => {
    state.showUserDeleteModel = false
    state.userBeingDeleted = null
}

export const setUserDetails = (state, data) => {
    console.log(data.data);
    state.userEdit = data.data
    state.userEdit.name = data.data.name
    state.userEdit.last_name = data.data.last_name
    state.userEdit.email = data.data.email
    state.userEdit.user_role = data.show_name
    state.userEdit.user_role = data.data.details.roles[0].name
    // console.log(data.data.details.roles[0].name);
    state.userEdit.middle_name = data.data.middle_name
    state.userEdit.designation = data.data.designation
    state.userEdit.department = data.data.department
    state.userEdit.company_name = data.data.company
    state.userEdit.designation = data.data.designation
    state.userEdit.city =  data.data.details.city
    state.userEdit.state =  data.data.details.state
    state.userEdit.country =  data.data.details.country
    state.userEdit.pin_code =  data.data.details.pin_code
    state.userEdit.address_line_1 =  data.data.details.address_line_1
    state.userEdit.company_name =  data.data.company_name
    state.userEdit.company_tel =  data.data.details.company_tel
    state.userEdit.company_fax =  data.data.details.company_fax
    state.userEdit.mobile_number =  data.data.details.mobile_number
    state.userEdit.website =  data.data.details.website
    state.userEdit.company_logo =  data.data.company_logo
    state.userEdit.address_line_2 =  data.data.details.address_line_2
    state.userEdit.address_line_3 =  data.data.details.address_line_3
}
export default [
{
	path: '/membership-form',
	component: () => import('../components/RequestForMembership.vue'),
	name: 'membership-form',
	meta: {
		guest: true,
		needsAuth: false
	}
},

{
	path: '/membership',
	component: () => import('../components/Membership.vue'),
	name: 'membership',
	meta: {
		guest: true,
		needsAuth: false
	}
},

]

export default [
{
	path: '/contact-us',
	component: () => import('../components/ContactUs.vue'),
	name: 'contact-us',
	
	meta: {
		
	}
},

]

export const showDeleteModel = (state, {flag, value}) => {
	state.showDeleteModel = flag
	state.idDeleteReferralPlan = value
}
export const hideDeleteModel = (state) => {
	state.showDeleteModel = false
	state.idDeleteReferralPlan = false	
}

export const showInviteReferrals = (state, flag) => {
	state.showInviteReferrals = flag
}
export const hideInviteReferrals = (state) => {
	state.showInviteReferrals = false
}

////intergation fetch referral list
export const SET_REFERRAL_LIST = (state, data) => {
	console.log(data);
    state.referralList.data = data
    state.referralList.links = data.links
}
export const setReferralId = (state, data) => {
	state.referralList.referralId = data;
 }

export default [
	{
		path : '/forgot-password',
		component: () => import('../components/ForgotPassword.vue'),
		name : 'forgot-password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},
	{
		path : '/reset-password',
		component: () => import('../components/ResetPassword.vue'),
		name : 'reset-password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},

	{
		path : '/change-password',
		component: () => import('../components/ChangePassword.vue'),
		name : 'change-password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},

		{
		path : '/sign-in',
		component: () => import('../components/SignIn.vue'),
		name : 'sign-in',
		meta: {
			guest: true,
			needsAuth: false
		}
	},

]
